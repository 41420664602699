import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from '@azure/msal-react';
import React, { FormEvent, useEffect, useState } from 'react';
import CostForm from './components/cost/CostForm';
import { CostsList } from './components/cost/CostsList';
import { PageLayout } from './components/PageLayout';
import TenantForm from './components/tenant/TenantForm';
import { TenantList } from './components/tenant/TenantList';
import { Cost, CostDto } from './interfaces/Cost';
import { Tenant, TenantDto } from './interfaces/Tenant';
import {
  addAllContacts,
  createCost,
  getAllCosts,
} from './services/CostService';
import { createTenant, getAllTenants } from './services/TenantService';

export default function App() {
  const [costs, setCosts] = useState<Cost[]>([]);
  const [tenants, setTenants] = useState<Tenant[]>([]);
  const [token, setToken] = useState(null);
  const [costFormData, setCostFormData] = useState<CostDto>({
    email: '',
    cost: 0,
  });
  const [tenantFormData, setTenantFormData] = useState<TenantDto>({
    id: '',
    displayName: '',
    isEnabled: true,
  });

  let showTenants = false;

  const getAndSetCostsHandler = async () => {
    const costs = await getAllCosts(token);
    setCosts(costs || []);
  };

  const addAllContactsHandler = async () => {
    await addAllContacts(token);
    await getAndSetCostsHandler();
  };

  const costFormOnSubmit = async (e: FormEvent) => {
    e.preventDefault();
    await createCost(token, costFormData);
    await getAndSetCostsHandler();
  };

  const getAndSetTenantsHandler = async () => {
    try {
      const tenants = await getAllTenants(token);
      setTenants(tenants || []);
      showTenants = true;
    } catch (error) {
      showTenants = false;
    }
  };

  const tenantFormOnSubmit = async (e: FormEvent) => {
    e.preventDefault();
    await createTenant(token, tenantFormData);
    await getAndSetTenantsHandler();
  };

  useEffect(() => {
    if (token) {
      getAndSetCostsHandler();
      getAndSetTenantsHandler();
    }
  }, [token]);

  return (
    <div className="App">
      <PageLayout onAccessTokenChange={setToken}>
        <AuthenticatedTemplate>
          <header className="rounded-md m-4 p-4 ring-4 ring-indigo-600 bg-indigo-600 text-white text-center">
            <div className="title">
              <div>
                <span className="text-4xl font-extrabold">CostMinder</span>
                <br />
                <span className="italic">Cost management</span>
              </div>
              <img className="logo" src="DS_tangram_white_big.png" alt="logo" />
            </div>
          </header>
          <div className="grid grid-cols-3 m-4 gap-x-4">
            <CostsList
              costs={costs}
              getAndSetCosts={getAndSetCostsHandler}
              token={token}
              addAllContacts={addAllContactsHandler}
            />
            <CostForm
              formData={costFormData}
              setFormData={setCostFormData}
              onSubmit={costFormOnSubmit}
            />
          </div>
          {showTenants ? (
            <div className="grid grid-cols-3 m-4 gap-x-4">
              <TenantList
                tenants={tenants}
                getAndSetTenants={getAndSetTenantsHandler}
                token={token}
              />
              <TenantForm
                formData={tenantFormData}
                setFormData={setTenantFormData}
                onSubmit={tenantFormOnSubmit}
              />
            </div>
          ) : null}
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <h5>
            <center>You are not signed in! Please sign in.</center>
          </h5>
        </UnauthenticatedTemplate>
      </PageLayout>
    </div>
  );
}
