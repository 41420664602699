import { useState } from 'react';
import { Tenant } from '../../interfaces/Tenant';
import TenantModal from './TenantModal';

export default function TenantItem(props: {
  tenant: Tenant;
  updateAndSetTenant: (id: string, data: Tenant) => Promise<void>;
  deleteOnClick: (id: string) => Promise<void>;
  getAndSetTenants: () => Promise<void>;
  token: string;
}) {
  const { tenant, deleteOnClick, getAndSetTenants, token } = props;

  const [showModal, setShowModal] = useState(false);

  return (
    <li key={tenant.id} className="TodoItem p-2">
      <TenantModal
        tenant={tenant}
        showModal={showModal}
        setShowModal={setShowModal}
        getAndSetTenants={getAndSetTenants}
        token={token}
      />
      <div className="flex justify-between hover:bg-slate-50 transition ease-in-out duration-100">
        <div className="flex">
          <div onClick={() => setShowModal(true)}>
            <div className="font-medium">{tenant.id}</div>
            <div className="font-medium">{tenant.displayName}</div>
            <div className="text-slate-400">{tenant.isEnabled}</div>
          </div>
        </div>
        <div>
          <button onClick={() => deleteOnClick(tenant.id)} className="mb-auto">
            x
          </button>
        </div>
      </div>
    </li>
  );
}
