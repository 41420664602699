import { InteractionRequiredAuthError } from '@azure/msal-common';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import React, { useEffect } from 'react';
import { addScope } from '../config';
import { SignInButton } from './buttons/SignInButton';
import { SignOutButton } from './buttons/SignOutButton';

export const PageLayout = ({ children, onAccessTokenChange }) => {
  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const name = accounts[0] && accounts[0].name;

  useEffect(() => {
    if (isAuthenticated) {
      const request = {
        scopes: [addScope],
        account: accounts[0],
      };

      instance
        .acquireTokenSilent(request)
        .then((tokenResponse) => {
          onAccessTokenChange(tokenResponse.accessToken);
        })
        .catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            // fallback to interaction when silent call fails
            return instance.acquireTokenRedirect(request);
          }

          // handle other errors
        });
    }
  }, [isAuthenticated]);

  return (
    <>
      <br />
      <h5>
        <center>Welcome to CostMinder {name}</center>
      </h5>
      <br />
      <div>{isAuthenticated ? <SignOutButton /> : <SignInButton />}</div>
      <br />
      {children}
    </>
  );
};
