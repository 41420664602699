import { useMsal } from '@azure/msal-react';
import React from 'react';
import { loginRequest } from '../../config';

/**
 * Renders a button which, when selected, will open a popup for login
 */
export const SignInButton = () => {
  const { instance } = useMsal();

  const handleLogin = () => {
    instance.loginRedirect(loginRequest).catch((e) => {
      console.log(e);
    });
  };
  return (
    <div className="flex justify-center space-x-4">
      <button
        className="border text-slate-500 rounded-xl px-5 py-1"
        onClick={() => handleLogin()}
      >
        Sign in
      </button>
    </div>
  );
};
