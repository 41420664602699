import { Tenant } from '../../interfaces/Tenant';
import { deleteTenant } from '../../services/TenantService';
import TenantItem from './TenantItem';

export function TenantList(props: {
  tenants: Tenant[];
  getAndSetTenants: () => Promise<void>;
  token: string;
}) {
  const { tenants, getAndSetTenants, token } = props;

  const deleteOnClick = async (id: string) => {
    await deleteTenant(token, id);
    await getAndSetTenants();
  };

  const updateAndSetTenant = async (id: string, data: Tenant) => {
    await getAndSetTenants();
  };

  const generateTenantListElements = (tenants: Tenant[]) => {
    return tenants.map((tenant) => {
      return (
        <TenantItem
          key={tenant.id}
          tenant={tenant}
          updateAndSetTenant={updateAndSetTenant}
          deleteOnClick={deleteOnClick}
          getAndSetTenants={getAndSetTenants}
          token={token}
        />
      );
    });
  };

  return (
    <div className="container col-span-2 border rounded-xl p-4 shadow-lg">
      <header className="text-2xl font-semibold underline decoration-2 decoration-indigo-600">
        Tenants
      </header>

      <ul className="TodoList container divide-y">
        {generateTenantListElements(tenants)}
      </ul>
    </div>
  );
}
