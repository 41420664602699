import { FormEvent, SetStateAction } from 'react';
import { TenantDto } from '../../interfaces/Tenant';

export default function TenantForm(props: {
  formData: TenantDto;
  setFormData: React.Dispatch<SetStateAction<TenantDto>>;
  onSubmit: (e: FormEvent) => void;
}) {
  const { formData, setFormData, onSubmit } = props;

  const handleClear = () => {
    setFormData({
      ...formData,
      id: '',
      displayName: '',
      isEnabled: true,
    });
  };
  return (
    <div className="container mb-auto border rounded-xl px-4 pt-4 shadow-lg">
      <header className="text-2xl mb-2 font-semibold underline decoration-2 decoration-indigo-600">
        Add tenant
      </header>
      <form
        className="container space-y-2 pb-4"
        onSubmit={(e) => {
          onSubmit(e);
          e.preventDefault();
          handleClear();
        }}
      >
        <input
          type="text"
          name="id"
          placeholder="Tenant id"
          className="focus:outline-none w-full font-medium text-lg"
          value={formData.id}
          onChange={(e) => {
            setFormData({ ...formData, id: e.target.value });
          }}
          required
        />
        <input
          type="text"
          name="displayName"
          placeholder="Tenant display name"
          className="focus:outline-none w-full font-medium text-lg"
          value={formData.displayName}
          onChange={(e) => {
            setFormData({ ...formData, displayName: e.target.value });
          }}
          required
        />
        <div className="flex justify-end space-x-4">
          <span>Is Enabled</span>
          <input
            type="checkbox"
            className="w-full text-sm focus:outline-none"
            name="isEnabled"
            placeholder="Enabled"
            checked={formData.isEnabled}
            onChange={(e) => {
              setFormData({ ...formData, isEnabled: e.target.checked });
            }}
          ></input>
        </div>
        <div className="flex justify-end space-x-4">
          <button
            className="border text-slate-500 rounded-xl px-5 py-1"
            onClick={(e) => {
              e.preventDefault();
              handleClear();
            }}
          >
            Clear
          </button>
          <button
            className="bg-indigo-600 text-white rounded-xl px-5 py-1"
            type="submit"
          >
            Add Tenant
          </button>
        </div>
      </form>
    </div>
  );
}
