import { Configuration } from '@azure/msal-browser';

export const msalConfig: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID,
    authority: 'https://login.microsoftonline.com/organizations',
    redirectUri: '/',
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

export const loginRequest = {
  scopes: ['User.Read'],
};

export const addScope = process.env.REACT_APP_ADD_SCOPE;

export const postLogoutRedirectUri =
  process.env.REACT_APP_POST_LOGOUT_REDIRECT_URI;

export const apiUrl = process.env.REACT_APP_API_URL;
