import axios from 'axios';
import { apiUrl } from '../config';
import { Cost, CostDto } from '../interfaces/Cost';

export const getAllCosts = async (token: string): Promise<Cost[]> => {
  const response = await axios.get(`${apiUrl}/costs`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  const status = response.status;
  console.log('DEBUG:');
  console.log('    GET STATUS:', status);
  return response.data;
};

export const getCost = async (token: string, id: number): Promise<Cost> => {
  const response = await axios.get(`${apiUrl}/costs/${id}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  const status = response.status;
  console.log('DEBUG:');
  console.log('    GET STATUS:', status);
  return response.data;
};

export const createCost = async (
  token: string,
  data: CostDto,
): Promise<number> => {
  const response = await axios.post(`${apiUrl}/costs/`, data, {
    headers: { Authorization: `Bearer ${token}` },
  });
  const status = response.status;
  console.log('DEBUG:');
  console.log('    CREATE STATUS:', status);
  return status;
};

export const updateCost = async (
  token: string,
  id: number,
  data: CostDto,
): Promise<number> => {
  const response = await axios.put(`${apiUrl}/costs/${id}`, data, {
    headers: { Authorization: `Bearer ${token}` },
  });
  const status = response.status;
  console.log('DEBUG:');
  console.log('    UPDATE STATUS:', status);
  return status;
};

export const deleteCost = async (
  token: string,
  id: number,
): Promise<number> => {
  const response = await axios.delete(`${apiUrl}/costs/${id}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  const status = response.status;
  console.log('DEBUG:');
  console.log('    DELETE STATUS:', status);
  return status;
};

export const addAllContacts = async (token: string): Promise<number> => {
  const response = await axios.post(
    `${apiUrl}/addAllContacts`,
    {},
    {
      headers: { Authorization: `Bearer ${token}` },
    },
  );
  const status = response.status;
  console.log('DEBUG:');
  console.log('    CREATE STATUS:', status);
  return status;
};
