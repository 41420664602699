import { SetStateAction, useState } from 'react';
import { Cost } from '../../interfaces/Cost';
import { updateCost } from '../../services/CostService';

export default function CostModal(props: {
  cost: Cost;
  showModal: boolean;
  setShowModal: React.Dispatch<SetStateAction<boolean>>;
  getAndSetCosts: () => Promise<void>;
  token: string;
}) {
  const { cost, showModal, setShowModal, getAndSetCosts, token } = props;

  const [costData, setCostData] = useState(cost);
  const [dataChanged, setDataChanged] = useState(false);

  const handleChange = () => {
    if (costData.email !== cost.email || costData.cost !== cost.cost) {
      setDataChanged(true);
    } else {
      setDataChanged(false);
    }
  };

  const handleSubmit = async () => {
    await updateCost(token, cost.id, { ...costData });
    await getAndSetCosts();
  };

  return (
    <div>
      {showModal ? (
        <div
          onClick={() => {
            setShowModal(false);
          }}
          className="fixed bg-zinc-700/50 inset-0 z-50"
        >
          <div className="flex justify-center items-center h-screen">
            <form
              onClick={(e) => e.stopPropagation()}
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
              className="container p-12 mx-20 my-10 bg-white border rounded-xl"
            >
              <div className="flex justify-between">
                <div className="flex justify-between align-middle grow space-x-4 mb-4">
                  <input
                    className="text-3xl grow font-semibold focus:outline-none"
                    name="title"
                    value={costData.email}
                    onKeyUp={handleChange}
                    onChange={(e) => {
                      setCostData({
                        ...costData,
                        email: e.target.value,
                      });
                    }}
                  />
                </div>
                <button onClick={() => setShowModal(false)}>x</button>
              </div>
              <textarea
                name="description"
                className="focus:outline-none w-full"
                value={costData.cost || ''}
                onKeyUp={handleChange}
                onChange={(e) => {
                  setCostData({ ...costData, cost: +e.target.value });
                }}
              ></textarea>
              <div className="flex space-x-4">
                <button
                  className="border text-slate-500 rounded-xl px-5 py-1 disabled:text-slate-300"
                  onClick={(e) => {
                    e.preventDefault();
                    setCostData(cost);
                    setDataChanged(false);
                  }}
                  disabled={!dataChanged}
                >
                  Reset
                </button>
                <button
                  className="bg-indigo-600 text-white rounded-xl px-5 py-1 disabled:opacity-50"
                  type="submit"
                  disabled={!dataChanged}
                >
                  Save changes
                </button>
              </div>
            </form>
          </div>
        </div>
      ) : null}
    </div>
  );
}
