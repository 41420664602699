import axios from 'axios';
import { apiUrl } from '../config';
import { Tenant, TenantDto } from '../interfaces/Tenant';

export const getAllTenants = async (token: string): Promise<Tenant[]> => {
  const response = await axios.get(`${apiUrl}/tenants`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  const status = response.status;
  console.log('DEBUG:');
  console.log('    GET STATUS:', status);
  return response.data;
};

export const getTenant = async (token: string, id: string): Promise<Tenant> => {
  const response = await axios.get(`${apiUrl}/tenants/${id}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  const status = response.status;
  console.log('DEBUG:');
  console.log('    GET STATUS:', status);
  return response.data;
};

export const createTenant = async (
  token: string,
  data: TenantDto,
): Promise<number> => {
  const response = await axios.post(`${apiUrl}/tenants/`, data, {
    headers: { Authorization: `Bearer ${token}` },
  });
  const status = response.status;
  console.log('DEBUG:');
  console.log('    CREATE STATUS:', status);
  return status;
};

export const updateTenant = async (
  token: string,
  id: string,
  data: TenantDto,
): Promise<number> => {
  const response = await axios.put(`${apiUrl}/tenants/${id}`, data, {
    headers: { Authorization: `Bearer ${token}` },
  });
  const status = response.status;
  console.log('DEBUG:');
  console.log('    UPDATE STATUS:', status);
  return status;
};

export const deleteTenant = async (
  token: string,
  id: string,
): Promise<number> => {
  const response = await axios.delete(`${apiUrl}/tenants/${id}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  const status = response.status;
  console.log('DEBUG:');
  console.log('    DELETE STATUS:', status);
  return status;
};
