import { Cost } from '../../interfaces/Cost';
import { deleteCost } from '../../services/CostService';
import CostItem from './CostItem';

export function CostsList(props: {
  costs: Cost[];
  getAndSetCosts: () => Promise<void>;
  token: string;
  addAllContacts: () => Promise<void>;
}) {
  const { costs, getAndSetCosts, token, addAllContacts } = props;

  const deleteOnClick = async (id: number) => {
    await deleteCost(token, id);
    await getAndSetCosts();
  };

  const updateAndSetCost = async (id: number, data: Cost) => {
    await getAndSetCosts();
  };

  const generateCostListElements = (costs: Cost[]) => {
    return costs.map((cost) => {
      return (
        <CostItem
          key={cost.id}
          cost={cost}
          updateAndSetCost={updateAndSetCost}
          deleteOnClick={deleteOnClick}
          getAndSetCosts={getAndSetCosts}
          token={token}
        />
      );
    });
  };

  return (
    <div className="container col-span-2 border rounded-xl p-4 shadow-lg">
      <header className="text-2xl font-semibold underline decoration-2 decoration-indigo-600">
        Costs
      </header>

      <ul className="TodoList container divide-y">
        {generateCostListElements(costs)}
      </ul>

      <div className="flex justify-end space-x-4">
        <button
          className="bg-indigo-600 text-white rounded-xl px-5 py-1"
          onClick={addAllContacts}
        >
          Add All Contacts
        </button>
      </div>
    </div>
  );
}
