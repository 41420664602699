import { FormEvent, SetStateAction } from 'react';
import { CostDto } from '../../interfaces/Cost';

export default function CostForm(props: {
  formData: CostDto;
  setFormData: React.Dispatch<SetStateAction<CostDto>>;
  onSubmit: (e: FormEvent) => void;
}) {
  const { formData, setFormData, onSubmit } = props;

  const handleClear = () => {
    setFormData({
      ...formData,
      email: '',
      cost: 0,
    });
  };

  return (
    <div className="container mb-auto border rounded-xl px-4 pt-4 shadow-lg">
      <header className="text-2xl mb-2 font-semibold underline decoration-2 decoration-indigo-600">
        Add meeting participant
      </header>
      <form
        className="container space-y-2 pb-4"
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit(e);
          handleClear();
        }}
      >
        <input
          type="text"
          name="email"
          placeholder="Email"
          className="focus:outline-none w-full font-medium text-lg"
          value={formData.email}
          onChange={(e) => {
            setFormData({ ...formData, email: e.target.value });
          }}
          required
        />
        <textarea
          className="w-full text-sm focus:outline-none"
          rows={5}
          name="cost"
          placeholder="Hourly cost"
          value={formData.cost || ''}
          onChange={(e) => {
            setFormData({ ...formData, cost: +e.target.value });
          }}
        ></textarea>
        <div className="flex justify-end space-x-4">
          <button
            className="border text-slate-500 rounded-xl px-5 py-1"
            onClick={(e) => {
              e.preventDefault();
              handleClear();
            }}
          >
            Clear
          </button>
          <button
            className="bg-indigo-600 text-white rounded-xl px-5 py-1"
            type="submit"
          >
            Add Cost
          </button>
        </div>
      </form>
    </div>
  );
}
