import { useState } from 'react';
import { Cost } from '../../interfaces/Cost';
import CostModal from './CostModal';

export default function CostItem(props: {
  cost: Cost;
  updateAndSetCost: (id: number, data: Cost) => Promise<void>;
  deleteOnClick: (id: number) => Promise<void>;
  getAndSetCosts: () => Promise<void>;
  token: string;
}) {
  const { cost, deleteOnClick, getAndSetCosts, token } = props;

  const [showModal, setShowModal] = useState(false);

  return (
    <li key={cost.id} className="TodoItem p-2">
      <CostModal
        cost={cost}
        showModal={showModal}
        setShowModal={setShowModal}
        getAndSetCosts={getAndSetCosts}
        token={token}
      />
      <div className="flex justify-between hover:bg-slate-50 transition ease-in-out duration-100">
        <div className="flex">
          <div onClick={() => setShowModal(true)}>
            <div className="font-medium">{cost.email}</div>
            <div className="text-slate-400">{cost.cost}</div>
          </div>
        </div>
        <div>
          <button onClick={() => deleteOnClick(cost.id)} className="mb-auto">
            x
          </button>
        </div>
      </div>
    </li>
  );
}
