import { useMsal } from '@azure/msal-react';
import React from 'react';
import { postLogoutRedirectUri } from '../../config';

export const SignOutButton = () => {
  const { instance } = useMsal();

  const handleLogout = () => {
    instance.logoutRedirect({
      postLogoutRedirectUri,
    });
  };

  return (
    <div className="flex justify-center space-x-4">
      <button
        className="border text-slate-500 rounded-xl px-5 py-1"
        onClick={() => handleLogout()}
      >
        Sign out
      </button>
    </div>
  );
};
