import { SetStateAction, useState } from 'react';
import { Tenant } from '../../interfaces/Tenant';
import { updateTenant } from '../../services/TenantService';

export default function TenantModal(props: {
  tenant: Tenant;
  showModal: boolean;
  setShowModal: React.Dispatch<SetStateAction<boolean>>;
  getAndSetTenants: () => Promise<void>;
  token: string;
}) {
  const { tenant, showModal, setShowModal, getAndSetTenants, token } = props;

  const [tenantData, setTenantData] = useState(tenant);
  const [dataChanged, setDataChanged] = useState(false);

  const handleChange = () => {
    if (
      tenantData.isEnabled !== tenant.isEnabled ||
      tenantData.displayName !== tenant.displayName
    ) {
      setDataChanged(true);
    } else {
      setDataChanged(false);
    }
  };

  const handleSubmit = async () => {
    await updateTenant(token, tenant.id, { ...tenantData });
    await getAndSetTenants();
  };

  return (
    <div>
      {showModal ? (
        <div
          onClick={() => {
            setShowModal(false);
          }}
          className="fixed bg-zinc-700/50 inset-0 z-50"
        >
          <div className="flex justify-center items-center h-screen">
            <form
              onClick={(e) => e.stopPropagation()}
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
              className="container p-12 mx-20 my-10 bg-white border rounded-xl"
            >
              <textarea
                name="description"
                readOnly={true}
                className="focus:outline-none w-full"
                value={tenantData.id}
              ></textarea>
              <textarea
                name="description"
                className="focus:outline-none w-full"
                value={tenantData.displayName}
                onKeyUp={handleChange}
                onChange={(e) => {
                  setTenantData({ ...tenantData, displayName: e.target.value });
                }}
              ></textarea>
              <label>Is active :</label>
              <input
                type="checkbox"
                className="text-3xl grow font-semibold focus:outline-none"
                name="title"
                checked={tenantData.isEnabled}
                onKeyUp={handleChange}
                onChange={(e) => {
                  setTenantData({
                    ...tenantData,
                    isEnabled: e.target.checked,
                  });
                }}
              />
              <div className="flex space-x-4">
                <button
                  className="border text-slate-500 rounded-xl px-5 py-1 disabled:text-slate-300"
                  onClick={(e) => {
                    e.preventDefault();
                    setTenantData(tenant);
                    setDataChanged(false);
                  }}
                  disabled={!dataChanged}
                >
                  Reset
                </button>
                <button
                  className="bg-indigo-600 text-white rounded-xl px-5 py-1 disabled:opacity-50"
                  type="submit"
                  disabled={!dataChanged}
                >
                  Save changes
                </button>
              </div>
            </form>
          </div>
        </div>
      ) : null}
    </div>
  );
}
